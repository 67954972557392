import { useEffect } from 'react';
import { resetParticipantData, setCallEnded, setCallInitiated } from '../redux-toolkit/reducer/teamsReducer';
import { useDispatch, useSelector } from 'react-redux';

export default function useCmdTeamCallEndedHook({ message }) {
    const dispatch = useDispatch();
    const portal = useSelector((state) => state?.portal?.portal);

    useEffect(() => {
        const handleCommand = async () => {
            const command = message?.data?.message || '';
            const commandData = message?.data?.data || {};

            if (command === 'team-call-ended' && commandData?.portal_id === portal.portal_id) {
                dispatch(setCallInitiated(false));
                dispatch(setCallEnded(true));
                dispatch(resetParticipantData());
            }
        };

        handleCommand();
    }, [message, dispatch, portal.portal_id]);
}
