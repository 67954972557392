import React, { useEffect, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './scss/style.scss';
import Loader from './components/loader';
import { store } from './redux-toolkit/store/configure';
import { setUser } from './redux-toolkit/reducer/userReducer';
// import cogoToast from 'cogo-toast';
// import CustomToast from './components/custom-toast';
// import { setEndBookedConnection } from './redux-toolkit/reducer/connectionReducer';
import { ErrorBoundary } from './components/ErrorBoundary';
import { useState } from 'react';
// import { useWebsocketController } from './utils';

sessionStorage.setItem('notifications', JSON.stringify([]));

// Containers
const Layout = React.lazy(() => import('./containers/Layout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const App = () => {
    const [isNetworkAvailable, setIsNetworkAvailable] = useState(true);
    useEffect(() => {
        store.dispatch(setUser({ orgId: 'shared_studios' }));
        sessionStorage.setItem('user', JSON.stringify({ orgId: 'shared_studios' }));
        // store.dispatch(getOrgDetailAPI({ orgId: 'shared_studios' }));
        /* store.dispatch(setUser({ orgId: process.env.REACT_APP_PORTAL_ID }));
        sessionStorage.setItem("user", JSON.stringify({ orgId: process.env.REACT_APP_PORTAL_ID })); */
        /* store.dispatch(setUser({ orgId: "sharedstudios_dev" }));
        sessionStorage.setItem("user", JSON.stringify({ orgId: "sharedstudios_dev" })); */

        if (!navigator.onLine) {
            setIsNetworkAvailable(false);
        }
    }, []);
    window.addEventListener('beforeunload', () => {
        sessionStorage.removeItem('flagWSCalled');
        sessionStorage.removeItem('wsConnectionEstablishedAt');
    });

    window.addEventListener('online', (event) => {
        setIsNetworkAvailable(true);
    });

    window.addEventListener('offline', (event) => {
        setIsNetworkAvailable(false);
    });

    // const { userObj } = useSelector(
    //     ({ user }) => ({
    //         userObj: user.userObj,
    //     }),
    //     _.isEqual,
    // );

    /* useEffect(() => {
        const date = new Date();
        const date1 =
            date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds() + "-" + date.getMilliseconds();
        console.log("Time: ", date1, "/", "userObj: ", userObj);
    }, [userObj]); */

    return (
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <BrowserRouter>
                    <Suspense fallback={<Loader header={''} content={''}></Loader>}>
                        <ErrorBoundary>
                            <Routes>
                                <Route exact path="/login" name="Login Page" element={<Login />} />
                                {/* <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
                                <Route path="*" name="Start" element={<Layout />} />
                            </Routes>
                        </ErrorBoundary>
                    </Suspense>
                    {/* <AppServiceUpdater /> */}
                    {/* {userObj?.orgId && userObj?.portalId && isEmpty(sessionStorage.getItem('flagWSCalled')) && (
                        // <WSConnection userObj={userObj}></WSConnection>
                    )} */}
                    {!isNetworkAvailable && (
                        <>
                            <div className="no-network">
                                <div user-select="none" className="content-section">
                                    No internet connection.
                                    <br /> Please check your network settings.
                                </div>
                            </div>
                        </>
                    )}
                </BrowserRouter>
            </Provider>
        </I18nextProvider>
    );
};

// export const WSConnection = (props) => {
//     sessionStorage.setItem('flagWSCalled', true);
//     sessionStorage.setItem('wsConnectionEstablishedAt', JSON.stringify(Date.now()));
//     const wsConnectionEstablishedAt = JSON.parse(sessionStorage.getItem('wsConnectionEstablishedAt'));
//     const dispatch = useDispatch();
//     let process = useCallback(
//         (message) => {
//             message = JSON.parse(message);
//             const notifications = JSON.parse(sessionStorage.getItem('notifications'));
//             if (!notifications) sessionStorage.setItem('notifications', '[]');

//             const executeCommand = async () => {
//                 if (!notifications.includes(message?.notificationId)) {
//                     sessionStorage.setItem('notifications', JSON.stringify([...notifications, message?.notificationId]));
//                     if (message && message.name === 'booking-ended-automatically') {
//                         dispatch(setEndBookedConnection(true));
//                     }
//                     const hideToast = () => {
//                         const ctToast = document.getElementsByClassName('ct-toast')[0];
//                         ctToast.style.setProperty('display', 'none');
//                     };

//                     const markNotificationAsRead = () => {
//                         sendWebsocketMessage(
//                             JSON.stringify({
//                                 event: 'mark-notification-as-delivered',
//                                 data: {
//                                     notificationId: message?.notificationId,
//                                 },
//                             }),
//                         );
//                     };

//                     if (message?.createdOn > wsConnectionEstablishedAt) {
//                         cogoToast.success(<CustomToast msg={message?.notification} />, {
//                             position: 'top-center',
//                             hideAfter: 5,
//                             renderIcon: () => {},
//                             onClick: () => hideToast(),
//                         });
//                     }

//                     markNotificationAsRead();
//                 }
//             };

//             executeCommand();
//         },
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//         [props, dispatch],
//     );
//     sendWebsocketMessage = useWebsocketController(
//         props.userObj.orgId,
//         props.userObj.portalId,
//         wsConnectionEstablishedAt,
//         process,
//     );
// };

export default App;
