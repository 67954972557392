import axios from 'axios';
import { ENDPOINT_DEV } from './configs';
export const getListOfPortalsAPI = async (orgPortal) => {
    const queryParams = [];
    if (orgPortal.limit) {
        queryParams.push(`limit=${orgPortal.limit}`);
    }
    queryParams.push(`isController=true`);
    const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

    return await axios.get(ENDPOINT_DEV + `organization/${orgPortal.orgId}/portal` + queryString).then((res) => {
        return res;
    });
};
