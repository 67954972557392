import { useEffect } from 'react';
import { setCallEnded, setCallInitiated, setConnectionError } from '../redux-toolkit/reducer/teamsReducer';
import { useDispatch, useSelector } from 'react-redux';

export default function useCmdTeamInitiateCallHook({ message }) {
    const dispatch = useDispatch();
    const portal = useSelector((state) => state?.portal?.portal);

    useEffect(() => {
        const handleCommand = async () => {
            const command = message?.data?.message || '';
            const commandData = message?.data?.data || {};

            if (command === 'team-call-initiated' && commandData?.portal_id === portal.portal_id) {
                dispatch(setCallInitiated(true));
                dispatch(setCallEnded(false));
            }

            if (command === 'team-call-initiation-error' && commandData?.portal_id === portal.portal_id) {
                dispatch(setConnectionError(true));
            }
        };

        handleCommand();
    }, [message, dispatch, portal]);
}
